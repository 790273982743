<template>
  <div>
    <v-row>
      <v-col>
        <s-crud
          :single-row="false"
          add
          addNoFunction
          @add="addWorker()"
          searchInput
          @rowSelected="selectedWorker($event)"
          ref="gridWorker"
          title="Trabajadores"
          :config="config"
          :filter="filterWorker"
          @doubleClick="assignWorker($event)"
        >
          <template v-slot:filter>
            <v-container>
              <v-row justify="center"
                ><v-col lg="6">
                  <s-select-area
                    v-model="filterWorker.AreID"
                    label="Área" /></v-col
              ></v-row>
            </v-container>
          </template>
        </s-crud>
      </v-col>
      <!-- </v-row>
    <v-row> -->
      <v-col v-if="isWorkerSelected">
        <s-crud
          :single-row="true"
          searchInput
          ref="gridScheduleWorker"
          add
          edit
          remove
          @save="save($event)"
          title="Horario de trabajo"
          :config="configScheduleWorker"
          :filter="filterScheduleWorker"
        >
          <template v-slot:filter>
            <v-container>
              <v-row>
                <v-col>
                  <s-date
                    label="Fecha inicio"
                    type="date"
                    v-model="filterScheduleWorker.SdwDateIni"
                    clearable
                  ></s-date>
                </v-col>
                <v-col>
                  <s-date
                    label="Fecha fin"
                    type="date"
                    clearable
                    v-model="filterScheduleWorker.SdwDateEnd"
                  ></s-date>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot="props">
            <v-container>
              <v-row>
                <v-col>
                  <s-select
                    label="Horario"
                    :items="scheduleWorker"
                    autocomplete
                    clearable
                    item-text="SdsSchedule"
                    item-value="SdsID"
                    v-model="props.item.SdsID"
                  ></s-select>
                </v-col>
                <v-col>
                  <s-date
                    label="Fecha inicio"
                    type="date"
                    v-model="props.item.SdwDateIni"
                  ></s-date>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    style="padding: 0; margin: 0"
                    :clearable="true"
                    @click="isUndefined = !isUndefined"
                    v-model="props.item.SdwUndefined"
                    label="Indefinido"
                  />
                </v-col>
                <v-col
                  v-if="
                    (!isUndefined && props.item.SdwUndefined != 1) ||
                    (isUndefined && props.item.SdwUndefined != 1)
                  "
                >
                  <s-date
                    label="Fecha fin"
                    type="date"
                    v-model="props.item.SdwDateEnd"
                  ></s-date>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:SdwDateEnd="{ row }">
            <div v-if="row.SdwUndefined == ''">
              {{ $fun.formatDateView(row.SdwDateEnd) }}
            </div>
            <div v-else>
              <v-icon>mdi-infinity</v-icon>
            </div>
          </template>
          <template v-slot:SecStatus="{ row }">
            <v-chip
              x-small
              :color="
                isDateInRange(row.SdwDateIni, row.SdwDateEnd)
                  ? 'success'
                  : 'error'
              "
            >
              {{
                isDateInRange(row.SdwDateIni, row.SdwDateEnd)
                  ? "Activo"
                  : "Inactivo"
              }}
            </v-chip>
          </template>
          <template v-slot:actions="{ row }">
            <div key="row.SdwID">
              <v-btn
                color="error"
                x-small
                @click="dialogScheduleDetailOpen(row)"
                block
              >
                <v-icon small>mdi-alarm-plus</v-icon>
              </v-btn>
            </div>
          </template>
        </s-crud>
      </v-col>
    </v-row>
    <v-row v-if="itemsCard.length > 0">
      <v-col cols="6">
        <v-card>
          <s-toolbar
            label="Trabajadores asignados"
            :remove="this.itemsCard.length > 0"
            :search="false"
            @removed="removeEvent($event)"
          >
            <v-tooltip bottom="">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  text
                  black
                  @click="generateMassiveSchedule()"
                  small
                >
                  <v-icon>mdi-wrench-clock</v-icon>
                </v-btn>
              </template>
              <span>Guardar Horarios Masivos</span>
            </v-tooltip>
          </s-toolbar>
          <v-data-table
            dense
            @click:row="rowClick"
            selectable-key="isSelectable"
            v-model="selected"
            :headers="configSelected.headers"
            item-key="PrsDocumentNumber"
            :items="itemsCard"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="openSchedule" max-width="85%" persistent>
      <v-card style="padding: 20px">
        <v-row style="padding: 10px" dense>
          <v-col cols="12" sm="3">
            <s-select
              label="Horario"
              :items="schedules"
              autocomplete
              clearable
              item-text="SdsSchedule"
              item-value="SdsID"
              v-model="modalItem.SdsID"
            ></s-select>
          </v-col>
          <v-col cols="12" sm="3">
            <s-date
              label="Fecha inicio"
              type="date"
              v-model="modalItem.SdwDateIni"
              clearable
            ></s-date>
          </v-col>
          <v-col cols="12" sm="3">
            <v-checkbox
              style="padding: 0; margin: 0"
              :clearable="true"
              @click="isUndefined = !isUndefined"
              v-model="modalItem.SdwUndefined"
              label="Indefinido"
            />
          </v-col>
          <v-col cols="12" sm="3" v-if="isUndefined">
            <s-date
              label="Fecha fin"
              type="date"
              clearable
              v-model="modalItem.SdwDateEnd"
            ></s-date>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn @click="saveMassiveSchedule()" color="primary" class="mr-2">
              Guardar
            </v-btn>
            <v-btn @click="openSchedule = false" color="error">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="dialogScheduleDetail"
      v-model="dialogScheduleDetail"
      width="800"
      persistent
    >
      <s-toolbar
        color="primary"
        dark
        close
        @close="dialogScheduleDetail = false"
        save
        @save="saveDetails()"
        :label="'Detalle de horario : '"
      >
      </s-toolbar>
      <v-card style="padding: 20px">
        <!-- <s-crud
          :single-row="true"
          searchInput
          ref="gridScheduleDetail"
          add
          :edit="false"
          @edit="editDetailSchedule($event)"
          remove
          addNoFunction
          @saved="saveDetails($event)"
          title=""
          :config="configScheduleDetail"
          :filter="filterScheduleDetail"
        >
          <template v-slot:filter></template>
          <template >
            <v-container>
              <v-row>
                <v-col>
                  <s-select-definition
                    v-model="pro.detail.SddType"
                    :def="1525"
                    label="Tipo"
                    ref="TypeName"
                  ></s-select-definition>
                </v-col>
                <v-col>
                  <s-text
                    type="number"
                    v-model="pro.detail.SddMin"
                    label="Min Ref."
                  ></s-text>
                </v-col>
                <v-col>
                  <s-text
                    type="time"
                    v-model="pro.detail.SddHourBegin"
                    label="Hora Inicio"
                  ></s-text>
                </v-col>
                <v-col>
                  <s-text
                    type="time"
                    v-model="pro.detail.SddHourEnd"
                    label="Hora Final"
                  ></s-text>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex flex-wrap">
                    <v-col style="padding:0">
                      <v-checkbox
                        v-model="selectAll"
                        label="Seleccionar Todos"
                        :value="true"
                        @change="toggleSelectAll"
                      ></v-checkbox>
                    </v-col>
                    <v-checkbox
                      v-for="item in days"
                      :value="item.DedValue"
                      v-model="pro.detail.days"
                      :label="item.DedDescription"
                      :key="item.DedValue"
                      class="mr-2"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:actions>
            <v-icon small @click="editDetailSchedule(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteDetailSchedule(item)">
              mdi-delete
            </v-icon>
          </template>
        </s-crud> -->
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="4">
                <s-select-definition
                  v-model="detail.SddType"
                  :def="1525"
                  label="Tipo"
                  ref="TypeName"
                ></s-select-definition>
              </v-col>
              <v-col cols="2">
                <s-text
                  type="number"
                  v-model="detail.SddMin"
                  label="Min Ref."
                ></s-text>
              </v-col>
              <v-col cols="3">
                <s-text
                  type="time"
                  v-model="detail.SddHourBegin"
                  label="Hora Inicio"
                ></s-text>
              </v-col>
              <v-col cols="3">
                <s-text
                  type="time"
                  v-model="detail.SddHourEnd"
                  label="Hora Final"
                ></s-text>
              </v-col>
              <v-col cols="12">
                <div class="d-flex flex-wrap">
                  <v-col style="padding: 0">
                    <v-checkbox
                      v-model="selectAll"
                      label="Seleccionar Todos"
                      :value="true"
                      @change="toggleSelectAll"
                    ></v-checkbox>
                  </v-col>
                  <v-checkbox
                    v-for="item in days"
                    :value="item.DedValue"
                    v-model="detail.days"
                    :label="item.DedDescription"
                    :key="item.DedValue"
                    @change="selectThis(item.DedValue)"
                    class="mr-2"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-data-table
              dense
              fixed-header=""
              loading="isLoading"
              loading-text="Loading... Please wait"
              group-by="SddTypeName"
              :items="itemsScheduled"
              :headers="configScheduleDetail.headers"
              :item-key="config.model.SwdID"
              class="elevation-1"
            >
              <template v-slot:item.AsgActionScheduled="{ item }">
                <v-btn
                  color="primary"
                  x-small
                  @click="editDetailSchedule(item)"
                  block
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  x-small
                  @click="deleteDetailSchedule(item)"
                  block
                >
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import _sHmnScheduleWorkerService from "../../../services/HumanResource/Compensation/HmnScheduleWorkerService";
import _sHmnScheduleWorkerDetailService from "../../../services/HumanResource/Compensation/HmnScheduleWorkerDetailService";
import _sHmnWorkerService from "../../../services/HumanResource/WorkerService";
import _sHmnScheduleService from "../../../services/HumanResource/Schedules";
import _sDefinition from "@/services/General/DefinitionService.js";
import SCrud from "../../../components/Utils/SCrud.vue";

export default {
  components: { SCrud },
  data() {
    return {
      paramsDef: {
        defID: 1524,
        dgrID: 0,
        requestID: this.$fun.getUserID(),
      },
      scheduleWorker: [],
      schedules: [],
      filterWorker: { AreID: 0 },
      selectionBefore: [],
      itemsCard: [],
      selected: [],
      openSchedule: false,
      dialogScheduleDetail: false,
      dialogName: "",
      selectAll: false,
      days: [],
      detail: {
        SddType: 1,
        SddMin: 45,
        SddHourBegin: null,
        SddHourEnd: null,
        days: [],
      },
      itemsScheduled: [],
      configScheduleDetail: {
        service: _sHmnScheduleWorkerDetailService,
        model: {
          SwdID: "ID",
          SdwDateIni: "date",
          SdwDateEnd: "date",
          SdsID: "int",
          SecStatus: "boolean",
        },
        headers: [
          { text: "Dia", value: "SddDateName", sortable: false },
          { text: "Hora inicio", value: "SddHourBegin", sortable: false },
          { text: "Hora final", value: "SddHourEnd", sortable: false },
          {
            text: "Tipo de horario",
            value: "SddTypeName",
            sortable: true,
          },
          { text: "Refrigerio", value: "SddMin", sortable: true },
          {
            text: "Accion",
            value: "AsgActionScheduled",
            sortable: false,
            with: "40px",
          },
        ],
        AsgActionScheduled: "",
      },
      filterScheduleDetail: {
        SdwID: 0,
      },
      row: {},
      modalItem: {
        SdwDateIni: null,
        SdwDateEnd: null,
        SdwUndefined: 0,
        SdsID: 0,
      },
      filterScheduleWorker: {
        SdsID: 0,
        SdwDateIni: null,
        SdwDateEnd: null,
      },
      isUndefined: true,
      isLoading: false,
      isWorkerSelected: false,
      config: {
        service: _sHmnWorkerService,
        model: {
          PrsDocumentNumber: "ID",
        },
        headers: [
          { text: "WkrID", value: "WkrID", sortable: false },
          { text: "Empleado", value: "WkrName", sortable: true },
          { text: "Área", value: "AreName", sortable: true },
        ],
      },
      configSelected: {
        headers: [
          { text: "ID", value: "WkrID", sortable: true },
          { text: "Documento", value: "PrsDocumentNumber", sortable: false },
          { text: "Trabajador", value: "WkrName", sortable: true },
        ],
      },
      configScheduleWorker: {
        service: _sHmnScheduleWorkerService,
        model: {
          SdwID: "ID",
          SdwDateIni: "date",
          SdwDateEnd: "date",
          SdsID: "int",
          SecStatus: "boolean",
          actions: "",
        },
        headers: [
          { text: "Fecha inicio", value: "SdwDateIni", sortable: false },
          { text: "Fecha final", value: "SdwDateEnd", sortable: false },
          { text: "Horario", value: "SdsName", sortable: false },
          {
            text: "Tipo de horario",
            value: "SdsTypeScheduleName",
            sortable: true,
          },
          { text: "Estado", value: "SecStatus", sortable: true },
          { text: "Acción", value: "actions", sortable: false },
        ],
      },
      loading: false,
    };
  },
  methods: {
    addWorker() {
      //console.log(this.selectionBefore);
      this.selectionBefore.forEach((element) => {
        if (this.itemsCard.find((x) => x == element) == null)
          this.itemsCard.push(element);
      });
    },
    selectedWorker(items) {
      this.selectionBefore = items;
    },
    removeEvent() {
      if (this.selected.length == 1) {
        this.itemsCard = this.itemsCard.filter((x) => x != this.selected[0]);
      }
    },
    rowClick: function (item) {
      this.selected = [item];
      this.row = Object.assign({}, item);
    },
    generateMassiveSchedule() {
      this.openSchedule = true;
    },
    saveMassiveSchedule() {
      this.$fun.alert("Seguro de guardar?", "question").then((resp) => {
        if (resp.value) {
          this.itemsCard.forEach((element) => {
            const event = {
              WkrID: element.WkrID,
              SdwDateIni: this.modalItem.SdwDateIni,
              SdwDateEnd: this.modalItem.SdwDateEnd,
              SdwUndefined: this.modalItem.SdwUndefined ? 1 : 0,
              SecStatus: 1,
              SdsID: this.modalItem.SdsID,
              UsrCreateID: this.$fun.getUserID(),
            };
            _sHmnScheduleWorkerService.save(event, this.$fun.getUserID())
            .then((s) => {
              //console.log("save success", s);
              
              this.$fun.alert("Guardado exisosamente", "success");
              this.$refs.gridScheduleWorker.refresh();
            })
            /* .catch((error) => {
              console.log("save error", error);
              
              this.$fun.alert(error, "error");
            }) */.finally(() => {
              this.openSchedule = false;
              //limpiar campos de trabajadores asignados
              this.itemsCard = [];
            });
          });
          this.openSchedule = false;
          this.$refs.gridWorker.refresh();
        }
      });
    },
    isDateInRange(startDate, endDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      let end;

      if (endDate != null && endDate !== "") {
        end = new Date(endDate);
        end.setHours(0, 0, 0, 0);
      }

      if (endDate == null || endDate == "") {
        return today >= start;
      }

      return today >= start && today <= end;
    },
    assignWorker(worker) {
      this.isWorkerSelected = true;
      this.filterScheduleWorker.WkrID = worker.WkrID;
      _sHmnScheduleService.list().then((response) => {
        this.scheduleWorker = response.data;
        this.$refs.gridScheduleWorker.refresh();
      });
    },
    save(event) {
      this.$fun.alert("Seguro de guardar?", "question").then((resp) => {
        if (resp.value) {
          event.WkrID = this.filterScheduleWorker.WkrID;
          event.UsrCreateID = this.$fun.getUserID();
          event.SdwUndefined = event.SdwUndefined ? 1 : 0;
          event.SecStatus = 1;
          this.isLoading = true;
          _sHmnScheduleWorkerService
            .save(event, this.$fun.getUserID())
            .then(() => {
              this.isLoading = false;
              this.$fun.alert("Guardado exisosamente", "success");
              this.$refs.gridScheduleWorker.refresh();
            });
        } else {
          this.isLoading = false;
        }
      });
    },
    dialogScheduleDetailOpen(event) {
      _sHmnScheduleWorkerDetailService.list(event.SdwID).then((response) => {
        this.dialogScheduleDetail = true;
        this.dialogName = event.WkrName || "Nuevo";
        this.filterScheduleDetail.SdwID = event.SdwID;
        this.itemsScheduled = response.data;
        this.clearInputs();
      });
    },
    clearInputs() {
      this.detail.SddType = 1;
      this.detail.SddMin = 45;
      this.detail.SddHourBegin = null;
      this.detail.SddHourEnd = null;
      this.detail.days = [];
      this.selectAll = false;
    },
    validateInputs() {
      if (!this.detail.SddHourBegin || !this.detail.SddHourEnd) {
        this.$fun.alert(
          "Debe seleccionar una hora de inicio y fin.",
          "warning"
        );
        return;
      }
      if (this.detail.SddMin < 0) {
        this.$fun.alert(
          "El tiempo de refrigerio no puede ser menor a 0.",
          "warning"
        );
        return;
      }
      return true;
    },
    saveDetails() {
      this.isLoading = true;
      if (!this.validateInputs()) return;
      //ver si hay varios dias
      this.detail.SecStatus = 1;
      this.detail.SdwID = this.filterScheduleDetail.SdwID;
      this.detail.UsrCreateID = this.$fun.getUserID();
      if (this.detail.days.length > 1) {
        this.detail.days.forEach((item) => {
          this.detail.SddDate = item;
          _sHmnScheduleWorkerDetailService
            .save(this.detail, this.$fun.getUserID())
            .then((r) => {
              if (r.status === 200) {
                _sHmnScheduleWorkerDetailService
                  .list(this.detail.SdwID)
                  .then((response) => {
                    this.itemsScheduled = response.data;
                    this.clearInputs();
                  });
              }
            })
            .catch((error) => {
              this.$fun.alert(error, "error");
            });
        });
      } else {
        this.detail.SddDate = this.detail.days[0];
        _sHmnScheduleWorkerDetailService
          .save(this.detail, this.$fun.getUserID())
          .then((r) => {
            if (r.status === 200) {
              _sHmnScheduleWorkerDetailService
                .list(this.detail.SdwID)
                .then((response) => {
                  this.itemsScheduled = response.data;
                  this.clearInputs();
                });
            }
          })
          .catch((error) => {
            this.$fun.alert(error, "error");
          });
      }
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.detail.days = this.days.map((item) => item.DedValue);
      } else {
        this.detail.days = [];
      }
    },
    editDetailSchedule(item) {
      //console.log("editDetailSchedule", item);

      this.detail = item;
      this.detail.days = [item.SddDate];
      this.selectAll = false;
    },
    deleteDetailSchedule(item) {
      this.$fun.alert("Desea eliminar registro?", "question").then((r) => {
        if (r.value) {
          this.isLoading = true;
          item.secStatus = 0;
          _sHmnScheduleWorkerDetailService
            .save(item, this.$fun.getUserID())
            .then((r) => {
              if (r.status === 200) {
                this.$fun.alert("Registro eliminado.", "success");
                this.itemsScheduled = this.itemsScheduled.filter(
                  (x) => x.SwdID != item.SwdID
                );
              } else {
                this.$fun.alert("No se pudo eliminar el registro.", "error");
              }
            })
            .catch((error) => {
              this.$fun.alert(error, "error");
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
  props: {
    item: {
      SdwDateIni: null,
      SdwDateEnd: null,
      SdwUndefined: 0,
      SdsID: 0,
    },
  },
  watch: {},
  created() {
    _sHmnScheduleService.list().then((response) => {
      this.schedules = response.data;
    });
    _sDefinition
      .definitiongroup(
        this.paramsDef.defID,
        this.paramsDef.dgrID,
        this.paramsDef.requestID
      )
      .then((r) => {
        if (r.status === 200) {
          this.days = r.data;
        }
      })
      .catch(() => {
        this.$fun.alert("No se pudieron cargar los días.");
      });
  },
};
</script>
  