import Service from "../../Service";

const resource = "HmnScheduleWorkerDetail/";

export default {
  save(obj, requestID) {
    return Service.post(resource + "save", obj, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  list(SdwID = 0) {
    return Service.post(
      resource + "list",
      {},
      {
        params: {
          SdwID: SdwID,
        },
      }
    );
  },
};
