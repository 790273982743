import Vue from "vue";
import Service from "../../Service";

const resource = "hmnScheduleWorker/";

export default {
  save(obj, requestID) {
    return Service.post(resource + "save", obj, {
      params: { requestID: requestID },
    });
  },
  pagination(dtr, requestID) {
    return Service.post(resource + "pagination", dtr, {
      params: { requestID: requestID },
    });
  },
  list() {
    return Service.post(resource + "list", dtr, {
      params: {},
    });
  },
};
